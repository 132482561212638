import list from 'src/modules/recipeReview/list/recipeReviewListReducers';
import form from 'src/modules/recipeReview/form/recipeReviewFormReducers';
import view from 'src/modules/recipeReview/view/recipeReviewViewReducers';
import destroy from 'src/modules/recipeReview/destroy/recipeReviewDestroyReducers';
import importerReducer from 'src/modules/recipeReview/importer/recipeReviewImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
