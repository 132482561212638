import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.recipeReview.fields.id'),
  },
  {
    name: 'bp',
    label: i18n('entities.recipeReview.fields.bp'),
  },
  {
    name: 'linebuildId',
    label: i18n('entities.recipeReview.fields.linebuildId'),
  },
  {
    name: 'batchId',
    label: i18n('entities.recipeReview.fields.batchId'),
  },
  {
    name: 'recipeName',
    label: i18n('entities.recipeReview.fields.recipeName'),
  },
  {
    name: 'location',
    label: i18n('entities.recipeReview.fields.location'),
  },
  {
    name: 'userEmail',
    label: i18n('entities.recipeReview.fields.userEmail'),
  },
  {
    name: 'items',
    label: i18n('entities.recipeReview.fields.items'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.recipeReview.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.recipeReview.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
