import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/recipeReview/importer/recipeReviewImporterSelectors';
import RecipeReviewService from 'src/modules/recipeReview/recipeReviewService';
import fields from 'src/modules/recipeReview/importer/recipeReviewImporterFields';
import { i18n } from 'src/i18n';

const recipeReviewImporterActions = importerActions(
  'RECIPEREVIEW_IMPORTER',
  selectors,
  RecipeReviewService.import,
  fields,
  i18n('entities.recipeReview.importer.fileName'),
);

export default recipeReviewImporterActions;