import { connectRouter } from 'connected-react-router';
import layout from 'src/modules/layout/layoutReducers';
import auth from 'src/modules/auth/authReducers';
import tenant from 'src/modules/tenant/tenantReducers';
import plan from 'src/modules/plan/planReducers';
import user from 'src/modules/user/userReducers';
import auditLog from 'src/modules/auditLog/auditLogReducers';
import settings from 'src/modules/settings/settingsReducers';
import labs from 'src/modules/labs/labsReducers';
import ingredient from 'src/modules/ingredient/ingredientReducers';
import bpLabs from 'src/modules/bpLabs/bpLabsReducers';
import bpIngredient from 'src/modules/bpIngredient/bpIngredientReducers';
import batches from 'src/modules/batches/batchesReducers';
import supplier from 'src/modules/supplier/supplierReducers';
import ingredientsSuppliers from 'src/modules/ingredientsSuppliers/ingredientsSuppliersReducers';
import supplyHistory from 'src/modules/supplyHistory/supplyHistoryReducers';
import menuItem from 'src/modules/menuItem/menuItemReducers';
import ingredientsUnits from 'src/modules/ingredientsUnits/ingredientsUnitsReducers';
import category from 'src/modules/category/categoryReducers';
import labStocks from 'src/modules/labStocks/labStocksReducers';
import storageType from 'src/modules/storageType/storageTypeReducers';
import type from 'src/modules/type/storageTypeReducers';
import ingredientRoot from 'src/modules/ingredientRoot/storageTypeReducers';
import bpCode from 'src/modules/bpCode/bpCodeReducers';
import unit from 'src/modules/unit/unitReducers';
import marketBrand from 'src/modules/marketBrand/marketBrandReducers';
import recipeItem from 'src/modules/recipeItem/recipeItemReducers';
import item from 'src/modules/item/itemReducers';
import lineBuild from 'src/modules/lineBuild/lineBuildReducers';
import lineBuildItem from 'src/modules/lineBuildItem/lineBuildItemReducers';
import conversionUnit from 'src/modules/conversionUnit/conversionUnitReducers';
import menuCategory from 'src/modules/menuCategory/menuCategoryReducers';
import lineBuildAddon from 'src/modules/lineBuildAddon/lineBuildAddonReducers';
import lineBuildExtra from 'src/modules/lineBuildExtra/lineBuildExtraReducers';
import lineBuildMenuItemMapping from 'src/modules/lineBuildMenuItemMapping/lineBuildMenuItemMappingReducers';
import cutting from 'src/modules/cutting/cuttingReducers';
import purchaseOrder from 'src/modules/purchaseOrder/purchaseOrderReducers';
import draftRecipeItem from 'src/modules/draftRecipeItem/recipeItemReducers';
import draftItem from 'src/modules/draftItem/itemReducers';
import draftLineBuild from 'src/modules/draftLineBuild/lineBuildReducers';
import draftLineBuildItem from 'src/modules/draftLineBuildItem/lineBuildItemReducers';
import skuIngredientsOp from "src/modules/skuIngredientsOp/skuIngredientsOpReducers"; 
import brandMenuCategory from 'src/modules/brandMenuCategory/brandMenuCategoryReducers';
import customerRequest from 'src/modules/customerRequest/customerRequestReducers';
import brandMenu from 'src/modules/brandMenu/brandMenuReducers';
import brandMenuItem from "src/modules/brandMenuItem/brandMenuItemReducers";
import comboItem from "src/modules/comboItem/comboItemReducers";
import combo from "src/modules/combo/comboReducers";
import productionPlan from 'src/modules/productionPlan/productionPlanReducers';
import productionOrder from 'src/modules/productionOrder/productionOrderReducers';
import recipeReview from "src/modules/recipeReview/recipeReviewReducers";


import { combineReducers } from 'redux';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    layout,
    auth,
    tenant,
    plan,
    user,
    auditLog,
    settings,
    labs,
    ingredient,
    bpLabs,
    bpIngredient,
    batches,
    supplier,
    ingredientsSuppliers,
    supplyHistory,
    menuItem,
    ingredientsUnits,
    category,
    labStocks,
    storageType,
    type, 
    ingredientRoot, 
    bpCode,
    unit,
    marketBrand,
    recipeItem,
    item,
    lineBuild,
    lineBuildItem,
    conversionUnit,
    menuCategory,
    lineBuildAddon,
    lineBuildExtra,
    lineBuildMenuItemMapping,
    cutting,
    purchaseOrder,
    draftRecipeItem, 
    draftItem, 
    draftLineBuild, 
    draftLineBuildItem,
    skuIngredientsOp,
    brandMenuCategory,
    brandMenu, 
    customerRequest, 
    brandMenuItem, 
    comboItem,
    combo, 
    productionPlan,
    productionOrder, 
    recipeReview, 
});
