import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.productionOrder.fields.id'),
  },
  {
    name: 'mo',
    label: i18n('entities.productionOrder.fields.mo'),
  },
  {
    name: 'sku',
    label: i18n('entities.productionOrder.fields.sku'),
  },
  {
    name: 'batch_name',
    label: i18n('entities.productionOrder.fields.batchName'),
  },
  {
    name: 'qty_required',
    label: i18n('entities.productionOrder.fields.qtyRequested'),
  },
  {
    name: 'moq',
    label: i18n('entities.productionOrder.fields.moq'),
  },
  {
    name: 'expected_date',
    label: i18n('entities.productionOrder.fields.expectedDate'),
  },
  {
    name: 'location',
    label: i18n('entities.productionOrder.fields.location'),
  },
  {
    name: 'qty_produced',
    label: i18n('entities.productionOrder.fields.qtyProduced'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.productionOrder.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.productionOrder.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
