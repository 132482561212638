import Roles from 'src/security/roles';
import Plans from 'src/security/plans';
import Storage from 'src/security/storage';

const storage = Storage.values;
const roles = Roles.values;
const plans = Plans.values;

class Permissions {
  static get values() {
    return {
      tenantEdit: {
        id: 'tenantEdit',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      tenantDestroy: {
        id: 'tenantDestroy',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      planEdit: {
        id: 'planEdit',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      planRead: {
        id: 'planRead',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userEdit: {
        id: 'userEdit',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userDestroy: {
        id: 'userDestroy',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userCreate: {
        id: 'userCreate',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userImport: {
        id: 'userImport',
        allowedRoles: [roles.admin, roles.read, roles.ownsBrandManager, 
          roles.ownsBrandAssociate, ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userRead: {
        id: 'userRead',
        allowedRoles: [roles.admin, roles.read, roles.ownsBrandManager, 
          roles.ownsBrandAssociate, ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userAutocomplete: {
        id: 'userAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.custom,
          roles.onboarding,
          roles.onboardingManager,
          roles.ops,
          roles.opsManager,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      auditLogRead: {
        id: 'auditLogRead',
        allowedRoles: [
          roles.admin,
          roles.ops,
          roles.opsManager,
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate, 
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      settingsEdit: {
        id: 'settingsEdit',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [
          storage.settingsBackgroundImages,
          storage.settingsLogos,
        ],
      },
      labsImport: {
        id: 'labsImport',
        allowedRoles: [roles.admin, roles.read, roles.ownsBrandManager, 
          roles.ownsBrandAssociate, ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      labsCreate: {
        id: 'labsCreate',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      labsEdit: {
        id: 'labsEdit',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      labsDestroy: {
        id: 'labsDestroy',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      labsRead: {
        id: 'labsRead',
        allowedRoles: [roles.admin, roles.custom, roles.ownsBrandManager, 
          roles.ownsBrandAssociate, ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      labsAutocomplete: {
        id: 'labsAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      ingredientImport: {
        id: 'ingredientImport',
        allowedRoles: [
          roles.admin, roles.read, 
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate, 
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      ingredientCreate: {
        id: 'ingredientCreate',
        allowedRoles: [
          roles.admin,
          roles.ops,
          roles.opsManager,
          roles.procurement,
          roles.procurementManager,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [
          // storage.ingredientPackagingImages,
        ],
      },
      ingredientEdit: {
        id: 'ingredientEdit',
        allowedRoles: [
          roles.admin,
          roles.ops,
          roles.opsManager,
          roles.procurement,
          roles.procurementManager,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [
          // storage.ingredientPackagingImages,
        ],
      },
      ingredientDestroy: {
        id: 'ingredientDestroy',
        allowedRoles: [
          roles.admin,
          roles.ops,
          roles.opsManager,
          roles.procurement,
          roles.procurementManager,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [
          // storage.ingredientPackagingImages,
        ],
      },
      ingredientRead: {
        id: 'ingredientRead',
        allowedRoles: [
          roles.admin,
          roles.finance,
          roles.financeManager,
          roles.onboarding,
          roles.onboardingManager,
          roles.ops,
          roles.opsManager,
          roles.procurement,
          roles.procurementManager,
          roles.read,
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate, 
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      ingredientAutocomplete: {
        id: 'ingredientAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.finance,
          roles.financeManager,
          roles.onboarding,
          roles.onboardingManager,
          roles.ops,
          roles.opsManager,
          roles.procurement,
          roles.procurementManager,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      bpLabsImport: {
        id: 'bpLabsImport',
        allowedRoles: [roles.admin, roles.read,  roles.ownsBrandManager, 
          roles.ownsBrandAssociate, ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      bpLabsCreate: {
        id: 'bpLabsCreate',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      bpLabsEdit: {
        id: 'bpLabsEdit',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      bpLabsDestroy: {
        id: 'bpLabsDestroy',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      bpLabsRead: {
        id: 'bpLabsRead',
        allowedRoles: [roles.admin, roles.custom,  roles.ownsBrandManager, 
          roles.ownsBrandAssociate, ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      bpLabsAutocomplete: {
        id: 'bpLabsAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      bpIngredientImport: {
        id: 'bpIngredientImport',
        allowedRoles: [
          roles.admin, 
          roles.read, 
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate,  
          roles.ops, 
          roles.opsManager, 
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      bpIngredientCreate: {
        id: 'bpIngredientCreate',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      bpIngredientEdit: {
        id: 'bpIngredientEdit',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      bpIngredientDestroy: {
        id: 'bpIngredientDestroy',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      bpIngredientRead: {
        id: 'bpIngredientRead',
        allowedRoles: [
          roles.admin, 
          roles.custom,  
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate,  
          roles.ops, 
          roles.opsManager, 
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      bpIngredientAutocomplete: {
        id: 'bpIngredientAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      batchesImport: {
        id: 'batchesImport',
        allowedRoles: [roles.admin, roles.read,  roles.ownsBrandManager, 
          roles.ownsBrandAssociate,  roles.ops, 
          roles.opsManager,  ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      batchesCreate: {
        id: 'batchesCreate',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      batchesEdit: {
        id: 'batchesEdit',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      batchesDestroy: {
        id: 'batchesDestroy',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      batchesRead: {
        id: 'batchesRead',
        allowedRoles: [roles.admin, roles.custom,  roles.ownsBrandManager, 
          roles.ownsBrandAssociate,  roles.ops, 
          roles.opsManager,  ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      batchesAutocomplete: {
        id: 'batchesAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      supplierImport: {
        id: 'supplierImport',
        allowedRoles: [roles.admin, roles.read,  roles.ownsBrandManager, 
          roles.ownsBrandAssociate,  roles.ops, 
          roles.opsManager,  ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      supplierCreate: {
        id: 'supplierCreate',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      supplierEdit: {
        id: 'supplierEdit',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      supplierDestroy: {
        id: 'supplierDestroy',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      supplierRead: {
        id: 'supplierRead',
        allowedRoles: [roles.admin, roles.custom,  roles.ownsBrandManager, 
          roles.ownsBrandAssociate, ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      supplierAutocomplete: {
        id: 'supplierAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      ingredientsSuppliersImport: {
        id: 'ingredientsSuppliersImport',
        allowedRoles: [roles.admin, roles.read,  roles.ownsBrandManager, 
          roles.ownsBrandAssociate,  roles.ops, 
          roles.opsManager,  ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      ingredientsSuppliersCreate: {
        id: 'ingredientsSuppliersCreate',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      ingredientsSuppliersEdit: {
        id: 'ingredientsSuppliersEdit',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      ingredientsSuppliersDestroy: {
        id: 'ingredientsSuppliersDestroy',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      ingredientsSuppliersRead: {
        id: 'ingredientsSuppliersRead',
        allowedRoles: [roles.admin, roles.custom,  roles.ownsBrandManager, 
          roles.ownsBrandAssociate, ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      ingredientsSuppliersAutocomplete: {
        id: 'ingredientsSuppliersAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      supplyHistoryImport: {
        id: 'supplyHistoryImport',
        allowedRoles: [
          roles.admin,
          roles.finance,
          roles.financeManager,
          roles.onboarding,
          roles.onboardingManager,
          roles.ops,
          roles.opsManager,
          roles.procurement,
          roles.procurementManager,
          roles.read,
           roles.ownsBrandManager, 
          roles.ownsBrandAssociate, 
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      supplyHistoryCreate: {
        id: 'supplyHistoryCreate',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [storage.supplyHistoryAttachment],
      },
      supplyHistoryEdit: {
        id: 'supplyHistoryEdit',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [storage.supplyHistoryAttachment],
      },
      supplyHistoryDestroy: {
        id: 'supplyHistoryDestroy',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [storage.supplyHistoryAttachment],
      },
      supplyHistoryRead: {
        id: 'supplyHistoryRead',
        allowedRoles: [
          roles.admin,
          roles.finance,
          roles.financeManager,
          roles.onboarding,
          roles.onboardingManager,
          roles.ops,
          roles.opsManager,
          roles.procurement,
          roles.procurementManager,
          roles.read,
           roles.ownsBrandManager, 
          roles.ownsBrandAssociate, 
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      supplyHistoryAutocomplete: {
        id: 'supplyHistoryAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.finance,
          roles.financeManager,
          roles.onboarding,
          roles.onboardingManager,
          roles.ops,
          roles.opsManager,
          roles.procurement,
          roles.procurementManager,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      menuItemImport: {
        id: 'menuItemImport',
        allowedRoles: [roles.admin, roles.read,  roles.ownsBrandManager, 
          roles.ownsBrandAssociate, ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      menuItemCreate: {
        id: 'menuItemCreate',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      menuItemEdit: {
        id: 'menuItemEdit',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      menuItemDestroy: {
        id: 'menuItemDestroy',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      menuItemRead: {
        id: 'menuItemRead',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
           roles.ownsBrandManager, 
          roles.ownsBrandAssociate, 
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      menuItemAutocomplete: {
        id: 'menuItemAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      ingredientsUnitsImport: {
        id: 'ingredientsUnitsImport',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
           roles.ownsBrandManager, 
          roles.ownsBrandAssociate, 
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      ingredientsUnitsCreate: {
        id: 'ingredientsUnitsCreate',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      ingredientsUnitsEdit: {
        id: 'ingredientsUnitsEdit',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      ingredientsUnitsDestroy: {
        id: 'ingredientsUnitsDestroy',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      ingredientsUnitsRead: {
        id: 'ingredientsUnitsRead',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
           roles.ownsBrandManager, 
          roles.ownsBrandAssociate, 
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      ingredientsUnitsAutocomplete: {
        id: 'ingredientsUnitsAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      categoryImport: {
        id: 'categoryImport',
        allowedRoles: [roles.admin, roles.read,  roles.ownsBrandManager, 
          roles.ownsBrandAssociate, ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      categoryCreate: {
        id: 'categoryCreate',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      categoryEdit: {
        id: 'categoryEdit',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      categoryDestroy: {
        id: 'categoryDestroy',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      categoryRead: {
        id: 'categoryRead',
        allowedRoles: [roles.admin, roles.custom,  roles.ownsBrandManager, 
          roles.ownsBrandAssociate, ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      categoryAutocomplete: {
        id: 'categoryAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      labStocksImport: {
        id: 'labStocksImport',
        allowedRoles: [roles.admin, roles.read,  roles.ownsBrandManager, 
          roles.ownsBrandAssociate, ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      labStocksCreate: {
        id: 'labStocksCreate',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      labStocksEdit: {
        id: 'labStocksEdit',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      labStocksDestroy: {
        id: 'labStocksDestroy',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      labStocksRead: {
        id: 'labStocksRead',
        allowedRoles: [roles.admin, roles.custom,  roles.ownsBrandManager, 
          roles.ownsBrandAssociate, ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      labStocksAutocomplete: {
        id: 'labStocksAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      storageTypeImport: {
        id: 'storageTypeImport',
        allowedRoles: [roles.admin, roles.read,  roles.ownsBrandManager, 
          roles.ownsBrandAssociate, ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      storageTypeCreate: {
        id: 'storageTypeCreate',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      storageTypeEdit: {
        id: 'storageTypeEdit',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      storageTypeDestroy: {
        id: 'storageTypeDestroy',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      storageTypeRead: {
        id: 'storageTypeRead',
        allowedRoles: [roles.admin, roles.custom,  roles.ownsBrandManager, 
          roles.ownsBrandAssociate, ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      storageTypeAutocomplete: {
        id: 'storageTypeAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      bpCodeImport: {
        id: 'bpCodeImport',
        allowedRoles: [roles.admin, roles.read,  roles.ownsBrandManager, 
          roles.ownsBrandAssociate, roles.ops, roles.opsManager],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      bpCodeCreate: {
        id: 'bpCodeCreate',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      bpCodeEdit: {
        id: 'bpCodeEdit',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      bpCodeDestroy: {
        id: 'bpCodeDestroy',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      bpCodeRead: {
        id: 'bpCodeRead',
        allowedRoles: [
          roles.admin, 
          roles.custom, 
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate, 
          roles.ops, 
          roles.opsManager
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      bpCodeAutocomplete: {
        id: 'bpCodeAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      unitImport: {
        id: 'unitImport',
        allowedRoles: [
          roles.admin, 
          roles.read, 
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate, 
          roles.ops, 
          roles.opsManager
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      unitCreate: {
        id: 'unitCreate',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      unitEdit: {
        id: 'unitEdit',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      unitDestroy: {
        id: 'unitDestroy',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      unitRead: {
        id: 'unitRead',
        allowedRoles: [
          roles.admin, 
          roles.custom, 
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate, 
          roles.ops, 
          roles.opsManager
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      unitAutocomplete: {
        id: 'unitAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      marketBrandImport: {
        id: 'marketBrandImport',
        allowedRoles: [
          roles.admin, 
          roles.read, 
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate, 
          roles.ops, 
          roles.opsManager
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      marketBrandCreate: {
        id: 'marketBrandCreate',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      marketBrandEdit: {
        id: 'marketBrandEdit',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      marketBrandDestroy: {
        id: 'marketBrandDestroy',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      marketBrandRead: {
        id: 'marketBrandRead',
        allowedRoles: [
          roles.admin, 
          roles.custom, 
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate,  
          roles.ops, 
          roles.opsManager,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      marketBrandAutocomplete: {
        id: 'marketBrandAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      recipeItemImport: {
        id: 'recipeItemImport',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate, 
          roles.ops, 
          roles.opsManager, 
          roles.kitchen, 
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      recipeItemCreate: {
        id: 'recipeItemCreate',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      recipeItemEdit: {
        id: 'recipeItemEdit',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      recipeItemDestroy: {
        id: 'recipeItemDestroy',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      recipeItemRead: {
        id: 'recipeItemRead',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate, 
          roles.ops, 
          roles.opsManager,
          roles.kitchen, 
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      recipeItemAutocomplete: {
        id: 'recipeItemAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
          roles.ops, 
          roles.opsManager,
          roles.kitchen, 
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      itemImport: {
        id: 'itemImport',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate, 
          roles.ops, 
          roles.opsManager,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      itemCreate: {
        id: 'itemCreate',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      itemEdit: {
        id: 'itemEdit',
        allowedRoles: [
          // roles.admin, roles.onboarding
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      itemDestroy: {
        id: 'itemDestroy',
        allowedRoles: [
          // roles.admin, roles.onboarding
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      itemRead: {
        id: 'itemRead',
        allowedRoles: [
          // roles.admin, roles.onboarding
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate, 
          roles.ops, 
          roles.opsManager,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      itemAutocomplete: {
        id: 'itemAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      draftRecipeItemImport: {
        id: 'recipeItemImport',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate, 
          roles.ops, 
          roles.opsManager,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      draftRecipeItemCreate: {
        id: 'draftRecipeItemCreate',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      draftRecipeItemEdit: {
        id: 'draftRecipeItemEdit',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      draftRecipeItemDestroy: {
        id: 'draftRecipeItemDestroy',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      draftRecipeItemRead: {
        id: 'draftRecipeItemRead',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate, 
          roles.ops, 
          roles.opsManager,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      draftRecipeItemAutocomplete: {
        id: 'draftRecipeItemAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      draftItemImport: {
        id: 'draftItemImport',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate, 
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      draftItemCreate: {
        id: 'draftItemCreate',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      draftItemEdit: {
        id: 'draftItemEdit',
        allowedRoles: [
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      draftItemDestroy: {
        id: 'draftItemDestroy',
        allowedRoles: [],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      draftItemRead: {
        id: 'draftItemRead',
        allowedRoles: [
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate, 
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      draftItemAutocomplete: {
        id: 'draftItemAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      
      lineBuildImport: {
        id: 'lineBuildImport',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate, 
          roles.ops, 
          roles.opsManager, 
          roles.kitchen, 
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      lineBuildCreate: {
        id: 'lineBuildCreate',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      lineBuildEdit: {
        id: 'lineBuildEdit',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      lineBuildDestroy: {
        id: 'lineBuildDestroy',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      lineBuildRead: {
        id: 'lineBuildRead',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate, 
          roles.ops, 
          roles.opsManager, 
          roles.kitchen, 
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      lineBuildAutocomplete: {
        id: 'lineBuildAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      lineBuildItemImport: {
        id: 'lineBuildItemImport',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate, 
          roles.ops, 
          roles.opsManager, 
          roles.kitchen, 
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      lineBuildItemCreate: {
        id: 'lineBuildItemCreate',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      lineBuildItemEdit: {
        id: 'lineBuildItemEdit',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      lineBuildItemDestroy: {
        id: 'lineBuildItemDestroy',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      lineBuildItemRead: {
        id: 'lineBuildItemRead',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate, 
          roles.ops, 
          roles.opsManager, 
          roles.kitchen, 
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      lineBuildItemAutocomplete: {
        id: 'lineBuildItemAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      conversionUnitImport: {
        id: 'conversionUnitImport',
        allowedRoles: [
          roles.admin, 
          roles.read, 
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate,  
          roles.ops, 
          roles.opsManager,  
          roles.kitchen, 
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      conversionUnitCreate: {
        id: 'conversionUnitCreate',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      conversionUnitEdit: {
        id: 'conversionUnitEdit',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      conversionUnitDestroy: {
        id: 'conversionUnitDestroy',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      conversionUnitRead: {
        id: 'conversionUnitRead',
        allowedRoles: [
          roles.admin, 
          roles.custom, 
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate, 
          roles.ops, 
          roles.opsManager,  
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      conversionUnitAutocomplete: {
        id: 'conversionUnitAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      menuCategoryImport: {
        id: 'menuCategoryImport',
        allowedRoles: [
          roles.admin, 
          roles.read, 
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate,  
          roles.ops, 
          roles.opsManager,  
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      menuCategoryCreate: {
        id: 'menuCategoryCreate',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },

      menuCategoryEdit: {
        id: 'menuCategoryEdit',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },

      menuCategoryDestroy: {
        id: 'menuCategoryDestroy',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },

      menuCategoryRead: {
        id: 'menuCategoryRead',
        allowedRoles: [
          roles.admin, 
          roles.custom, 
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate,  
          roles.ops, 
          roles.opsManager,  
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      menuCategoryAutocomplete: {
        id: 'menuCategoryAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      lineBuildAddonImport: {
        id: 'lineBuildAddonImport',
        allowedRoles: [
          roles.admin, roles.read, 
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate,  
          roles.ops, 
          roles.opsManager,  
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      lineBuildAddonCreate: {
        id: 'lineBuildAddonCreate',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },

      lineBuildAddonEdit: {
        id: 'lineBuildAddonEdit',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },

      lineBuildAddonDestroy: {
        id: 'lineBuildAddonDestroy',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      lineBuildAddonRead: {
        id: 'lineBuildAddonRead',
        allowedRoles: [
          roles.admin, 
          roles.custom, 
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate, 
          roles.ops, 
          roles.opsManager, 
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      lineBuildAddonAutocomplete: {
        id: 'lineBuildAddonAutocomplete',
        allowedRoles: [
          roles.admin, 
          roles.custom, 
          roles.ops, 
          roles.opsManager,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      lineBuildExtraImport: {
        id: 'lineBuildExtraImport',
        allowedRoles: [
          roles.admin, 
          roles.read, 
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate,  
          roles.ops, 
          roles.opsManager,  
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      lineBuildExtraCreate: {
        id: 'lineBuildExtraCreate',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      lineBuildExtraEdit: {
        id: 'lineBuildExtraEdit',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      lineBuildExtraDestroy: {
        id: 'lineBuildExtraDestroy',
        allowedRoles: [roles.admin, roles.read],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      lineBuildExtraRead: {
        id: 'lineBuildExtraRead',
        allowedRoles: [
          roles.admin, 
          roles.custom, 
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate,  
          roles.ops, 
          roles.opsManager,  
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      lineBuildExtraAutocomplete: {
        id: 'lineBuildExtraAutocomplete',
        allowedRoles: [roles.admin, roles.custom],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      lineBuildMenuItemMappingImport: {
        id: 'lineBuildMenuItemMappingImport',
        allowedRoles: [
          roles.admin,
          roles.onboardingManager,
          roles.onboarding,
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate, 
          roles.ops, 
          roles.opsManager, 
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      lineBuildMenuItemMappingCreate: {
        id: 'lineBuildMenuItemMappingCreate',
        allowedRoles: [
          roles.admin,
          roles.onboardingManager,
          roles.onboarding,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      lineBuildMenuItemMappingEdit: {
        id: 'lineBuildMenuItemMappingEdit',
        allowedRoles: [
          roles.admin,
          roles.onboardingManager,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      lineBuildMenuItemMappingDestroy: {
        id: 'lineBuildMenuItemMappingDestroy',
        allowedRoles: [
          roles.admin,
          roles.onboardingManager,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      lineBuildMenuItemMappingRead: {
        id: 'lineBuildMenuItemMappingRead',
        allowedRoles: [
          roles.admin,
          roles.onboardingManager,
          roles.onboarding,
          roles.read,
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate,
          roles.ops, 
          roles.opsManager, 
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      lineBuildMenuItemMappingAutocomplete: {
        id: 'lineBuildMenuItemMappingAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.onboardingManager,
          roles.onboarding,
          roles.read,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      cuttingImport: {
        id: 'cuttingImport',
        allowedRoles: [
          roles.admin,
          roles.read,
          roles.onboarding,
          roles.onboardingManager,
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate,
          roles.ops, 
          roles.opsManager, 
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      cuttingCreate: {
        id: 'cuttingCreate',
        allowedRoles: [
          roles.admin,
          roles.read,
          roles.onboarding,
          roles.onboardingManager,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [storage.cuttingCuttingImage],
      },
      cuttingEdit: {
        id: 'cuttingEdit',
        allowedRoles: [
          roles.admin,
          roles.read,
          roles.onboarding,
          roles.onboardingManager,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [storage.cuttingCuttingImage],
      },
      cuttingDestroy: {
        id: 'cuttingDestroy',
        allowedRoles: [
          roles.admin,
          roles.read,
          roles.onboarding,
          roles.onboardingManager,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [storage.cuttingCuttingImage],
      },
      cuttingRead: {
        id: 'cuttingRead',
        allowedRoles: [
          roles.admin,
          roles.read,
          roles.onboarding,
          roles.onboardingManager,
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate,
          roles.ops, 
          roles.opsManager, 
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      cuttingAutocomplete: {
        id: 'cuttingAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.read,
          roles.onboarding,
          roles.onboardingManager,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },

      purchaseOrderImport: {
        id: 'purchaseOrderImport',
        allowedRoles: [
          roles.admin,
          roles.onboardingManager,
          roles.onboarding,
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      purchaseOrderCreate: {
        id: 'purchaseOrderCreate',
        allowedRoles: [
          roles.admin,
          roles.onboardingManager,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      purchaseOrderEdit: {
        id: 'purchaseOrderEdit',
        allowedRoles: [
          roles.admin,
          roles.onboardingManager,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      purchaseOrderDestroy: {
        id: 'purchaseOrderDestroy',
        allowedRoles: [
          roles.admin,
          roles.onboardingManager,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [],
      },
      purchaseOrderRead: {
        id: 'purchaseOrderRead',
        allowedRoles: [
          roles.admin,
          roles.onboardingManager,
          roles.ownsBrandManager, 
          roles.ownsBrandAssociate,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      purchaseOrderAutocomplete: {
        id: 'purchaseOrderAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.onboardingManager,
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      brandMenuCategoryImport: {
        id: 'brandMenuCategoryImport',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      brandMenuCategoryCreate: {
        id: 'brandMenuCategoryCreate',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      brandMenuCategoryEdit: {
        id: 'brandMenuCategoryEdit',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      brandMenuCategoryDestroy: {
        id: 'brandMenuCategoryDestroy',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      brandMenuCategoryRead: {
        id: 'brandMenuCategoryRead',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      brandMenuCategoryAutocomplete: {
        id: 'brandMenuCategoryAutocomplete',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      customerRequestImport: {
        id: 'customerRequestImport',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      customerRequestCreate: {
        id: 'customerRequestCreate',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      customerRequestEdit: {
        id: 'customerRequestEdit',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      customerRequestDestroy: {
        id: 'customerRequestDestroy',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      customerRequestRead: {
        id: 'customerRequestRead',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      customerRequestAutocomplete: {
        id: 'customerRequestAutocomplete',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      brandMenuImport: {
        id: 'brandMenuImport',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      brandMenuCreate: {
        id: 'brandMenuCreate',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.brandMenuImage,
        ],
      },
      brandMenuEdit: {
        id: 'brandMenuEdit',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.brandMenuImage,
        ],
      },
      brandMenuDestroy: {
        id: 'brandMenuDestroy',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.brandMenuImage,
        ],
      },
      brandMenuRead: {
        id: 'brandMenuRead',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      brandMenuAutocomplete: {
        id: 'brandMenuAutocomplete',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      brandMenuItemImport: {
        id: 'brandMenuItemImport',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      brandMenuItemCreate: {
        id: 'brandMenuItemCreate',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.brandMenuItemImage,
        ],
      },
      brandMenuItemEdit: {
        id: 'brandMenuItemEdit',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.brandMenuItemImage,
        ],
      },
      brandMenuItemDestroy: {
        id: 'brandMenuItemDestroy',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.brandMenuItemImage,
        ],
      },
      brandMenuItemRead: {
        id: 'brandMenuItemRead',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      brandMenuItemAutocomplete: {
        id: 'brandMenuItemAutocomplete',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      comboItemImport: {
        id: 'comboItemImport',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      comboItemCreate: {
        id: 'comboItemCreate',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.comboItemImage,
        ],
      },
      comboItemEdit: {
        id: 'comboItemEdit',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.comboItemImage,
        ],
      },
      comboItemDestroy: {
        id: 'comboItemDestroy',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.comboItemImage,
        ],
      },
      comboItemRead: {
        id: 'comboItemRead',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      comboItemAutocomplete: {
        id: 'comboItemAutocomplete',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      comboImport: {
        id: 'comboImport',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      comboCreate: {
        id: 'comboCreate',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      comboEdit: {
        id: 'comboEdit',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      comboDestroy: {
        id: 'comboDestroy',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      comboRead: {
        id: 'comboRead',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      comboAutocomplete: {
        id: 'comboAutocomplete',
        allowedRoles: [roles.admin, roles.ownsBrandAssociate, roles.ownsBrandManager],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      productionPlanImport: {
        id: 'productionPlanImport',
        allowedRoles: [roles.admin, roles.kitchen,  roles.ops, roles.opsManager, ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      productionPlanCreate: {
        id: 'productionPlanCreate',
        allowedRoles: [roles.admin, roles.ops, roles.opsManager, ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      productionPlanEdit: {
        id: 'productionPlanEdit',
        allowedRoles: [roles.admin, roles.ops, roles.opsManager, ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      productionPlanDestroy: {
        id: 'productionPlanDestroy',
        allowedRoles: [roles.admin, roles.ops, roles.opsManager, ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      productionPlanRead: {
        id: 'productionPlanRead',
        allowedRoles: [roles.admin, roles.kitchen, roles.ops, roles.opsManager, ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      productionPlanAutocomplete: {
        id: 'productionPlanAutocomplete',
        allowedRoles: [roles.admin, roles.kitchen, roles.ops, roles.opsManager, ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      productionOrderImport: {
        id: 'productionOrderImport',
        allowedRoles: [roles.admin, roles.kitchen, roles.ops, roles.opsManager, ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      productionOrderCreate: {
        id: 'productionOrderCreate',
        allowedRoles: [roles.admin, roles.kitchen, roles.ops, roles.opsManager, ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      productionOrderEdit: {
        id: 'productionOrderEdit',
        allowedRoles: [roles.admin, roles.kitchen, roles.ops, roles.opsManager, ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      productionOrderDestroy: {
        id: 'productionOrderDestroy',
        allowedRoles: [roles.admin, roles.kitchen, roles.ops, roles.opsManager, ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      productionOrderRead: {
        id: 'productionOrderRead',
        allowedRoles: [roles.admin, roles.kitchen,  roles.ops, roles.opsManager, ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      productionOrderAutocomplete: {
        id: 'productionOrderAutocomplete',
        allowedRoles: [roles.admin, roles.kitchen, roles.ops, roles.opsManager, ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      recipeReviewImport: {
        id: 'recipeReviewImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      recipeReviewCreate: {
        id: 'recipeReviewCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      recipeReviewEdit: {
        id: 'recipeReviewEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      recipeReviewDestroy: {
        id: 'recipeReviewDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      recipeReviewRead: {
        id: 'recipeReviewRead',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      recipeReviewAutocomplete: {
        id: 'recipeReviewAutocomplete',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

    };  
  };

  static get asArray() {
    return Object.keys(this.values).map((value) => {
      return this.values[value];
    });
  }
}

export default Permissions;
