import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'bp',
    label: i18n('entities.recipeReview.fields.bp'),
    schema: schemas.string(
      i18n('entities.recipeReview.fields.bp'),
      {},
    ),
  },
  {
    name: 'linebuildId',
    label: i18n('entities.recipeReview.fields.linebuildId'),
    schema: schemas.string(
      i18n('entities.recipeReview.fields.linebuildId'),
      {},
    ),
  },
  {
    name: 'batchId',
    label: i18n('entities.recipeReview.fields.batchId'),
    schema: schemas.string(
      i18n('entities.recipeReview.fields.batchId'),
      {},
    ),
  },
  {
    name: 'recipeName',
    label: i18n('entities.recipeReview.fields.recipeName'),
    schema: schemas.string(
      i18n('entities.recipeReview.fields.recipeName'),
      {},
    ),
  },
  {
    name: 'location',
    label: i18n('entities.recipeReview.fields.location'),
    schema: schemas.string(
      i18n('entities.recipeReview.fields.location'),
      {},
    ),
  },
  {
    name: 'userEmail',
    label: i18n('entities.recipeReview.fields.userEmail'),
    schema: schemas.string(
      i18n('entities.recipeReview.fields.userEmail'),
      {},
    ),
  },
  {
    name: 'items',
    label: i18n('entities.recipeReview.fields.items'),
    schema: schemas.relationToMany(
      i18n('entities.recipeReview.fields.items'),
      {},
    ),
  },
];