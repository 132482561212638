import Permissions from 'src/security/permissions';
import { i18n } from 'src/i18n';
import React from 'react';
import PersonIcon from '@material-ui/icons/Person';
import HistoryIcon from '@material-ui/icons/History';
import SettingsIcon from '@material-ui/icons/Settings';
import HomeIcon from '@material-ui/icons/Home';
import {
  Fastfood, 
  MenuBook,
  LocalDining,
} from "@material-ui/icons"; 
import { GrommetIconsPackage } from './shared/icons/Packaging';
import { MaterialSymbolsDrafts } from "./shared/icons/Drafts"; 
import { MaterialSymbolsWarehouse } from "./shared/icons/Warehouse";  

export function BiTools(props) {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="2em" 
      height="2em" 
      viewBox="0 0 16 16" 
      {...props}>
      <path 
        fill="currentColor" 
        d="M1 0L0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675l-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617l.968.968l-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96l2.68-2.643A3.005 3.005 0 0 0 16 3q0-.405-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46L4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708M3 11l.471.242l.529.026l.287.445l.445.287l.026.529L5 13l-.242.471l-.026.529l-.445.287l-.287.445l-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471l.026-.529l.445-.287l.287-.445l.529-.026z"
      />
    </svg>
  )
}; 

const permissions = Permissions.values;

export default [
  {
    path: '/',
    exact: true,
    icon: <HomeIcon />,
    label: 'Home',
    permissionRequired: null,
  },
  {
    path: '/user',
    label: i18n('user.menu'),
    permissionRequired: permissions.userRead,
    icon: <PersonIcon />,
  },
  {
    path: '/audit-logs',
    icon: <HistoryIcon />,
    label: i18n('auditLog.menu'),
    permissionRequired: permissions.auditLogRead,
  },
  {
    path: '/settings',
    icon: <SettingsIcon />,
    label: i18n('settings.menu'),
    permissionRequired: permissions.settingsEdit,
  },

  {
    path: '/ingredient',
    permissionRequired: permissions.ingredientRead,
    icon: <Fastfood />,
    label: 'Ingredient',
    children: [
      {
        label: 'Master Ingredient List',
        path: '/ingredient',
      },
      {
        label: 'BP Ingredients',
        path: '/bp-ingredient',
      },
      {
        label: 'Unitless Ingredients',
        path: '/unitless-ingredient',
      },
      {
        label: 'Units Conversion',
        path: '/conversion-unit',
      },
      {
        label: 'Ingredient Units',
        path: '/ingredients-units',
      },
      {
        label: 'Categories',
        path: '/category',
      },
      {
        label: 'Market Brands',
        path: '/market-brand',
      },
      {
        label: 'Types',
        path: '/type',
      },
      {
        label: "IngredientRoots", 
        path: "/ingredient-root",
      },
    ],
  },

  {
    path: '/recipe-item',
    permissionRequired: permissions.ingredientRead,
    icon: <LocalDining />,
    label: 'Recipe',
    children: [
      {
        label: 'Batch Recipes',
        path: '/recipe-item',
      },
      {
        label: 'Menu Categories',
        path: '/menu-category',
      },
      {
        label: 'Common Batches', 
        path: '/common-batch',
      },
      {
        label: 'Recipe Replacement', 
        path: '/recipe-replacement',
      },
      {
        label: 'Line Builds',
        path: '/line-build',
      },
      {
        label: 'Ops Sheet',
        path: '/ops-sheet',
      },
      {
        label: 'Cuttings',
        path: '/cutting',
      },
      {
        label: 'Line Builds Addons',
        path: '/line-build-addon',
      },
      // {
      //   label: 'Recipe Reviews',
      //   path: '/recipe-review',
      // },
    ],
  },

  {
    path: '/customer-request',
    permissionRequired: permissions.ingredientRead,
    icon: <MenuBook />,
    label: 'Menu',
    children: [
      {
        label: 'Brand Menu Items',
        path: '/brand-menu-item',
      },   
      {
        label: 'BrandMenu Categories',
        path: '/brand-menu-category',
      },   
      {
        label: 'Combos',
        path: '/combo',
      },   
      {
        label: 'Customer Requests',
        path: '/customer-request',
      },       
    ],
  },
  {
    path: '/market-list',
    permissionRequired: null,
    icon: <BiTools />,
    label: 'Tools',
    children: [
      {
        label: 'LineBuilds Market List',
        path: '/market-list',
      },
      {
        label: 'Batches Market List', 
        path: '/batches-market-list',
      },
      {
        label: 'Line Builds Menu Items Mappings',
        path: '/line-build-menu-item-mapping',
      },
      {
        label: 'Ingredient Search',
        path: '/ingredientSearch',
      },
      {
        label: 'Ingredient Consumption',
        path: '/ingredientConsumption',
      },
      {
        label: 'Bulk Add MenuItems Costs',
        path: '/bulk-add-menu-item-costs',
      },
    ],
  },
  {
    path: '/packaging-list',
    permissionRequired: permissions.ingredientRead,
    icon: <GrommetIconsPackage />,
    label: 'Packaging',
    children: [
      {
        label: 'Packaging List',
        path: '/packaging-list',
      },
      {
        label: 'Packaging Consumption',
        path: '/packagingConsumption',
      },
      {
        label: 'Add Missing Packaging',
        path: '/add-missing-packaging',
      },
    ],
  },

  {
    path: '/draft-recipe-item',
    permissionRequired: permissions.ingredientRead,
    icon: <MaterialSymbolsDrafts />,
    label: 'Drafts',
    children: [
      {
        label: 'Draft Batches',
        path: '/draft-recipe-item',
      },
      {
        label: 'Draft Line Builds',
        path: '/draft-line-build',
      }, 
      {
        label: 'Draft Market List',
        path: '/draft-market-list',
      },
      {
        label: 'Draft BP Ingredients',
        path: '/draft-bp-ingredient',
      }, 
      {
        label: 'Draft Common Batches', 
        path: '/draft-common-batch'
      },
    ],
  },
  {
    path: '/production-plan',
    permissionRequired: permissions.productionPlanRead,
    icon: <MaterialSymbolsWarehouse />,
    label: 'Production',
    children: [
      {
        label: 'Production Plans',
        path: '/production-plan',
      },
      {
        label: 'Pending Production Orders',
        path: '/production-order',
      },
      {
        label: 'Completed Production Orders',
        path: '/completed-production-order',
      },
      {
        label: 'Production Orders Required Ingredients',
        path: '/production-orders-required-ingredients',
      },
      {
        label: 'Import Completed Production Orders',
        path: '/import-completed-production-orders',
      },
    ],
  }, 
  
]?.filter(Boolean);


export const BPMenu = [
  {
    path: '/',
    exact: true,
    icon: <HomeIcon />,
    label: 'Home',
    permissionRequired: null,
  },
  {
    path: '/user',
    label: i18n('user.menu'),
    permissionRequired: permissions.userRead,
    icon: <PersonIcon />,
  },
  {
    path: '/audit-logs',
    icon: <HistoryIcon />,
    label: i18n('auditLog.menu'),
    permissionRequired: permissions.auditLogRead,
  }, 
  {
    path: '/bp-ingredient',
    permissionRequired: null,
    icon: <Fastfood />,
    label: 'BP Ingredients',
    children: [
      {
        label: 'BP Ingredients',
        path: '/bp-ingredient',
      },
    ],
  },

  {
    path: '/recipe-item',
    permissionRequired: permissions.ingredientRead,
    icon: <LocalDining />,
    label: 'Recipe',
    children: [
      {
        label: 'Batch Recipes',
        path: '/recipe-item',
      },
      {
        label: 'Common Batches', 
        path: '/common-batch',
      },
      {
        label: 'Line Builds',
        path: '/line-build',
      },
      {
        label: 'Ops Sheet',
        path: '/ops-sheet',
      },
      {
        label: 'Cuttings',
        path: '/cutting',
      },
      {
        label: 'Line Builds Addons',
        path: '/line-build-addon',
      },
    ],
  },

  {
    path: '/customer-request',
    permissionRequired: permissions.ingredientRead,
    icon: <MenuBook />,
    label: 'Menu',
    children: [
      {
        label: 'Brand Menu Items',
        path: '/brand-menu-item',
      },   
      {
        label: 'BrandMenu Categories',
        path: '/brand-menu-category',
      },    
      {
        label: 'Combos',
        path: '/combo',
      },   
      {
        label: 'Customer Requests',
        path: '/customer-request',
      },       
    ],
  },
  {
    path: '/market-list',
    permissionRequired: null,
    icon: <BiTools />,
    label: 'Tools',
    children: [
      {
        label: 'Market List',
        path: '/market-list',
      },
      {
        label: 'Line Builds Menu Items Mappings',
        path: '/line-build-menu-item-mapping',
      },
      {
        label: 'Ingredient Search',
        path: '/ingredientSearch',
      },
      {
        label: 'Ingredient Consumption',
        path: '/ingredientConsumption',
      },
    ],
  },

  {
    path: '/packaging-list',
    permissionRequired: null,
    icon: <GrommetIconsPackage />,
    label: 'Packaging',
    children: [
      {
        label: 'Packaging List',
        path: '/packaging-list',
      },
      {
        label: 'Packaging Consumption',
        path: '/packagingConsumption',
      },
    ],
  },

].filter(Boolean);

export const OpsUser = [
  {
    path: '/',
    exact: true,
    icon: <HomeIcon />,
    label: 'Home',
    permissionRequired: null,
  },
  {
    path: '/user',
    label: i18n('user.menu'),
    permissionRequired: permissions.userRead,
    icon: <PersonIcon />,
  },
  {
    path: '/audit-logs',
    icon: <HistoryIcon />,
    label: i18n('auditLog.menu'),
    permissionRequired: permissions.auditLogRead,
  }, 
  {
    path: '/ingredient',
    permissionRequired: permissions.ingredientRead,
    icon: <Fastfood />,
    label: 'Ingredient',
    children: [
      {
        label: 'Master Ingredient List',
        path: '/ingredient',
      },
      {
        label: 'Unitless Ingredients',
        path: '/unitless-ingredient',
      },
    ],
  },
  {
    path: '/bp-ingredient',
    permissionRequired: null,
    icon: <Fastfood />,
    label: 'BP Ingredients',
    children: [
      {
        label: 'BP Ingredients',
        path: '/bp-ingredient',
      },
    ],
  },

  {
    path: '/recipe-item',
    permissionRequired: permissions.ingredientRead,
    icon: <LocalDining />,
    label: 'Recipe',
    children: [
      {
        label: 'Batch Recipes',
        path: '/recipe-item',
      },
      {
        label: 'Common Batches', 
        path: '/common-batch',
      },
      {
        label: 'Line Builds',
        path: '/line-build',
      },
      {
        label: 'Ops Sheet',
        path: '/ops-sheet',
      },
      {
        label: 'Cuttings',
        path: '/cutting',
      },
      {
        label: 'Line Builds Addons',
        path: '/line-build-addon',
      },
      // {
      //   label: 'Recipe Reviews',
      //   path: '/recipe-review',
      // },
    ],
  },

  {
    path: '/customer-request',
    permissionRequired: null,
    icon: <MenuBook />,
    label: 'Menu',
    children: [
      {
        label: 'Brand Menu Items',
        path: '/brand-menu-item',
      },   
      {
        label: 'BrandMenu Categories',
        path: '/brand-menu-category',
      },
      {
        label: 'Combos',
        path: '/combo',
      },   
      {
        label: 'Customer Requests',
        path: '/customer-request',
      },       
    ],
  },
  {
    path: '/market-list',
    permissionRequired: null,
    icon: <BiTools />,
    label: 'Tools',
    children: [
      {
        label: 'Market List',
        path: '/market-list',
      },
      {
        label: 'Line Builds Menu Items Mappings',
        path: '/line-build-menu-item-mapping',
      },
      {
        label: 'Ingredient Search',
        path: '/ingredientSearch',
      },
      {
        label: 'Ingredient Consumption',
        path: '/ingredientConsumption',
      },
      {
        label: 'Bulk Add MenuItems Costs',
        path: '/bulk-add-menu-item-costs',
      },
    ],
  },
  {
    path: '/packaging-list',
    permissionRequired: null,
    icon: <GrommetIconsPackage />,
    label: 'Packaging',
    children: [
      {
        label: 'Packaging List',
        path: '/packaging-list',
      },
      {
        label: 'Packaging Consumption',
        path: '/packagingConsumption',
      },
    ],
  },
  {
    path: '/production-plan',
    permissionRequired: permissions.productionPlanRead,
    icon: <MaterialSymbolsWarehouse />,
    label: 'Production',
    children: [
      {
        label: 'Production Plans',
        path: '/production-plan',
      },
      {
        label: 'Pending Production Orders',
        path: '/production-order',
      },
      {
        label: 'Completed Production Orders',
        path: '/completed-production-order',
      },
      {
        label: 'Production Orders Required Ingredients',
        path: '/production-orders-required-ingredients',
      },
      {
        label: 'Import Completed Production Orders',
        path: '/import-completed-production-orders',
      },
    ],
  }, 
].filter(Boolean);

